import React from 'react';
import logoPermata from './permata.svg';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

      <p>iframe 1</p>
      <iframe width="500" height="400" src="https://www.acc.co.id/" title="acc.co.id" allowfullscreen></iframe>

      <br/>

      <p>iframe 2</p>
      <iframe width="500" height="400" src="https://virtual-event-starter-kit-fajraii.vercel.app/" title="permatanet" allowfullscreen></iframe>

        <img src={logoPermata} className="App-logo" alt="logo" />
        <p>
          Click link below
        </p>
        <a
          class="App-link"
          href="https://www.permatanet.com/pnet/permatanet-pay?vaNumber=70087887696335&transactionDate=06-11-2020&amount=12000&callbackUrl=https://bukalapak.com&callbackUrl2=https://bukalapak.com&description=Pembayaran Pulsa"
          rel="noopener noreferrer"
          onClick="https://www.permatanet.com/pnet/permatanet-pay?vaNumber=70087887696335&transactionDate=06-11-2020&amount=12000&callbackUrl=https://bukalapak.com&callbackUrl2=https://bukalapak.com&description=Pembayaran Pulsa"
        >
          Redirect PROD
        </a>
        <br/>
        <br/>
        <p>
          Click link below
        </p>
        <a
          class="App-link"
          href="https://pnet.pbdevtest.com/pnet/permatanet-pay?vaNumber=8965050000000045&transactionDate=06-11-2020&amount=12000&callbackUrl=https://bukalapak.com&callbackUrl2=https://google.com&description=Pembayaran Edy"
          rel="noopener noreferrer"
          onClick="https://pnet.pbdevtest.com/pnet/permatanet-pay?vaNumber=8965050000000045&transactionDate=06-11-2020&amount=12000&callbackUrl=https://bukalapak.com&callbackUrl2=https://google.com&description=Pembayaran Edy"
        >
          Redirect UAT
        </a>


        <br/>
        <p>&nbsp;</p>

      </header>
    </div>
  );
}

export default App;
